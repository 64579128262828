
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import gQrCode from "@/components/g-qrcode.vue";
import gEmpty from "@/components/g-empty.vue";
import gRoomList from "@/components/g-room-list.vue";
import EventBus from "@/assets/js/EventBus";
import { betaServer, guideServer } from "@/assets/js/Request";

@Component({
  components: {
    gQrCode,
    gEmpty,
    gRoomList,
  },
})
export default class SGIndex extends Vue {
  private list = [];
  private page = 1;
  private limit = 30;
  private getMoreTag = true;
  private noMoreTag = false;
  private cid = 0;
  private isShowCode = false;
  private isTypeActive = false;
  private isStyleActive = false;
  private isRoomActive = false;
  private cTypeIndex = -1;
  private cStyleIndex = -1;
  private cRoomIndex = -1;
  private selectTypeIndex = -1; //点击空白后只保留点击了确认的选择
  private selectStyleIndex = -1;
  private selectRoomIndex = -1;
  private totalUsed = sessionStorage.getItem("totalUsed") || 0;
  private loading = true;
  private catelogList = {
    product_type: [],
    style_type: [],
    room_type: [],
  };

  @State online: any;
  @State control: any;
  @State("socketId") uid: any;

  get shareUrl(): string {
    return `${location.href}?uid=${this.uid}`;
  }

  get cType(): any {
    return this.cTypeIndex == -1
      ? { id: 0, name: "柜体类型" }
      : this.cTypeGroup[this.cTypeIndex];
  }

  get cStyle(): any {
    return this.cStyleIndex == -1
      ? { id: 0, name: "柜体风格" }
      : this.cStyleGroup[this.cStyleIndex];
  }

  get cRoom(): any {
    return this.cRoomIndex == -1
      ? { id: 0, name: "房间类型" }
      : this.cRoomGroup[this.cRoomIndex];
  }

  get checkType(): any {
    return this.cType.name;
  }

  get checkStyle(): any {
    return this.cStyle.name;
  }

  get checkRoom(): any {
    return this.cRoom.name;
  }

  get cTypeGroup(): Array<any> {
    return this.catelogList.product_type || [];
  }
  get cStyleGroup(): Array<any> {
    return this.catelogList.style_type || [];
  }
  get cRoomGroup(): Array<any> {
    return this.catelogList.room_type || [];
  }

  toGuideSearch(isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("toGuideSearch", "");
    }
    EventBus.$emit("toGuideSearch");
  }

  toGuideCollect(isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("toGuideCollect", "");
    }
    EventBus.$emit("toGuideCollect");
  }

  toGuideDetail(id: string, isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("toGuideDetail", id);
    }
    EventBus.$emit("toGuideDetail", id);
  }

  backTop(): void {
    // this.pos = 0;
    this.page = 1;
    this.getMoreTag = true;
    this.list = [];
    this.getGuideList();
  }

  switchType(index: number, isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("switchType", index);
    }
    this.cTypeIndex = index;
  }

  switchStyle(index: number, isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("switchStyle", index);
    }
    this.cStyleIndex = index;
  }

  switchRoom(index: number, isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("switchRoom", index);
    }
    this.cRoomIndex = index;
  }

  // 重置选择
  resetType(isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("resetType", "");
    }
    this.selectTypeIndex = -1;
    this.cTypeIndex = -1;
    this.cid = 0;
    this.isTypeActive = false;
    this.backTop();
  }

  resetStyle(isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("resetStyle", "");
    }
    this.selectStyleIndex = -1;
    this.cStyleIndex = -1;
    this.cid = 0;
    this.isStyleActive = false;
    this.backTop();
  }

  resetRoom(isSending = true): void {
    if (isSending) {
      this.$socket?.dispatchEvent("resetRoom", "");
    }
    this.selectRoomIndex = -1;
    this.cRoomIndex = -1;
    this.cid = 0;
    this.isRoomActive = false;
    this.backTop();
  }

  //确认选择
  confirmType(index: number, isSending = true): void {
    if (index == -1) return;
    if (isSending) {
      if (this.$socket)
        this.$socket?.dispatchEvent("confirmType", this.cTypeIndex);
    } else {
      this.cTypeIndex = index;
    }
    this.selectTypeIndex = this.cTypeIndex;
    this.cid = this.cType.id;
    this.backTop();
    this.isTypeActive = false;
    this.cStyleIndex = -1;
    this.cRoomIndex = -1;
    this.selectStyleIndex = -1;
  }

  confirmStyle(index: number, isSending = true): void {
    if (index == -1) return;
    if (isSending) {
      if (this.$socket)
        this.$socket?.dispatchEvent("confirmStyle", this.cStyleIndex);
    } else {
      this.cStyleIndex = index;
    }
    this.selectStyleIndex = this.cStyleIndex;
    this.cid = this.cStyle.id;
    this.backTop();
    this.isStyleActive = false;
    this.cTypeIndex = -1;
    this.cRoomIndex = -1;
    this.selectTypeIndex = -1;
  }

  confirmRoom(index: number, isSending = true): void {
    if (index == -1) return;
    if (isSending) {
      if (this.$socket)
        this.$socket?.dispatchEvent("confirmRoom", this.cRoomIndex);
    } else {
      this.cRoomIndex = index;
    }
    this.selectRoomIndex = this.cRoomIndex;
    this.cid = this.cRoom.id;
    this.backTop();
    this.isRoomActive = false;
    this.cTypeIndex = -1;
    this.cStyleIndex = -1;
    this.selectRoomIndex = -1;
  }

  getMore(): void {
    if (this.getMoreTag) {
      return;
    }
    if (this.noMoreTag) {
      this.$showToast("没有更多数据了");
      return;
    }
    this.page++;
    this.getMoreTag = true;
    this.getGuideList();
  }

  getGuideList(): void {
    let data;
    if (this.cid == 0) {
      data = {
        uid: this.uid || 0,
        page: this.page,
        limit: this.limit,
      };
    } else {
      data = {
        uid: this.uid || 0,
        catelogs: this.cid,
        page: this.page,
        limit: this.limit,
      };
    }
    this.$get(guideServer + "/shopping_guide/get_products", data).then(
      (res: any) => {
        let data = res.data.data;
        if (this.list.length > 0) {
          this.list = this.list.concat(data.data);
        } else {
          this.list = data.data;
        }

        this.loading = false;
        this.getMoreTag = false;
        this.noMoreTag = data.total <= this.page * this.limit;
      }
    );
  }
  getGuideCatlogs(): void {
    this.$get(guideServer + "/shopping_guide/get_catelogs", {
      groupid: 1,
    }).then((res: any) => {
      this.catelogList = res.data.data;
    });
  }

  toggleTypeGroup(bool: boolean, isSending = true): void {
    if (this.cTypeGroup.length < 1) {
      this.$showToast("类型加载中...");
      return;
    }
    if (isSending) {
      this.$socket?.dispatchEvent("toggleTypeGroup", bool);
    }
    this.isTypeActive = bool;
    this.isStyleActive = false;
    this.isRoomActive = false;
  }

  toggleStyleGroup(bool: boolean, isSending = true): void {
    if (this.cStyleGroup.length < 1) {
      this.$showToast("风格加载中...");
      return;
    }
    if (isSending) {
      this.$socket?.dispatchEvent("toggleStyleGroup", bool);
    }
    this.isStyleActive = bool;
    this.isTypeActive = false;
    this.isRoomActive = false;
  }

  toggleRoomGroup(bool: boolean, isSending = true): void {
    if (this.cRoomGroup.length < 1) {
      this.$showToast("户型加载中...");
      return;
    }
    if (isSending) {
      this.$socket?.dispatchEvent("toggleRoomGroup", bool);
    }
    this.isRoomActive = bool;
    this.isTypeActive = false;
    this.isStyleActive = false;
  }

  closeSelector(): void {
    if (this.isTypeActive) {
      this.toggleTypeGroup(false);
    }
    if (this.isStyleActive) {
      this.toggleStyleGroup(false);
    }
  }

  // 绑定事件
  handleMessage(): void {
    EventBus.$on("toggleTypeGroup", (data: any) => {
      this.toggleTypeGroup(data, false);
    });

    EventBus.$on("toggleStyleGroup", (data: any) => {
      this.toggleStyleGroup(data, false);
    });

    EventBus.$on("toggleRoomGroup", (data: any) => {
      this.toggleStyleGroup(data, false);
    });

    EventBus.$on("switchType", (data: any) => {
      this.switchType(data, false);
    });
    EventBus.$on("switchStyle", (data: any) => {
      this.switchStyle(data, false);
    });
    EventBus.$on("switchRoom", (data: any) => {
      this.switchStyle(data, false);
    });

    EventBus.$on("confirmType", (data: any) => {
      this.confirmType(data, false);
    });
    EventBus.$on("confirmStyle", (data: any) => {
      this.confirmStyle(data, false);
    });
    EventBus.$on("confirmRoom", (data: any) => {
      this.confirmStyle(data, false);
    });

    EventBus.$on("resetType", (data: any) => {
      this.resetType(false);
    });
    EventBus.$on("resetStyle", (data: any) => {
      this.resetStyle(false);
    });
    EventBus.$on("resetRoom", (data: any) => {
      this.resetStyle(false);
    });
  }

  getTotalUsed(): void {
    if (this.totalUsed) return;

    this.$get(betaServer + "/land/get_connect_status?token=eggidev").then(
      (res: any) => {
        let data = res.data;
        if (data.status && data.data.shopping_guide_history_online) {
          sessionStorage.setItem(
            "totalUsed",
            data.data.shopping_guide_history_online
          );
          this.totalUsed = data.data.shopping_guide_history_online;
        }
      }
    );
  }
  mounted(): void {
    this.getGuideList();
    this.getGuideCatlogs();

    if (this.$socket) {
      this.getTotalUsed();
      this.handleMessage();
    }
  }
}
